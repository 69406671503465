import React, { Component } from 'react';
import { Container, Collapse } from 'reactstrap';


export class ChatHeader extends Component {
    static displayName = ChatHeader.name;

    constructor(props) {
        super(props);

        this.baseInfoUrl = "https://zakupki.gov.ru";

        this.getLink = this.getLink.bind(this);
    }

    getLink(link) {
        if (!link) {
            return "";
        }

        return this.baseInfoUrl + link.replace(this.baseInfoUrl, "");
    }

    render() {
        const tenderName = this.props.info != null ? this.props.info.name : "";
        const menuButtonIcon = this.props.isMenuOpen ? 'fa-times' : 'fa-bars';

        return (
            <header data-id={this.props.id} className="chat-header">
                <div className="header-main">
                    <Container fluid={this.props.fluid}>
                        <button
                            className={menuButtonIcon + " fa menu-button float-right"}
                            onClick={this.props.onMenuClick}
                        />

                        <h1>
                            <span className="d-none d-sm-inline">Закупка </span>
                            <span>№</span>
                            <button className="btn btn-link" onClick={this.props.onTenderInfoToggle} title={tenderName}>{this.props.id}</button>
                        </h1>
                    </Container>
                </div>
                {
                    this.props.info &&
                    <Collapse isOpen={this.props.isTenderInfoOpen}>
                        <div className="header-info">
                            <Container fluid={this.props.fluid}>
                                <div>
                                    <a href={this.getLink(this.props.info.link)} target="_blank" rel="noopener noreferrer">
                                        {this.props.info.name}
                                    </a>
                                </div>
                                <hr />
                                <div>
                                    <a href={this.getLink(this.props.info.publisher.link)} target="_blank" rel="noopener noreferrer">
                                        {this.props.info.publisher.name}
                                    </a>
                                </div>
                            </Container>
                        </div>
                    </Collapse>
                }
            </header>
        );
    }
}
