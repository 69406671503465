import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';

export class ChatConditions extends Component {
    static displayName = ChatConditions.name;

    render() {
        return (
            <div id="chat-conditions">
                <Container>
                    <Row className="row-grid">
                        <Col lg={10} md={9}>Данный сервис использует куки браузера для хранения тенической информации. Используя данный сервис, Вы принимаете условия <Link to="/info/license/">лицензионного соглашения</Link> а также соглашаетесь с <a href="/info/privacy/">политикой обработки персональных данных</a>.</Col>
                        <Col lg={2} md={3}>
                            <button className="btn btn-primary w-100" onClick={this.props.onSubmit}>Принять <i className="fa fa-check" /></button>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default withRouter(ChatConditions);
