import 'react-app-polyfill/ie11';
import "react-app-polyfill/stable";
import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { createBrowserHistory } from 'history';
import 'font-awesome/css/font-awesome.min.css';

const readCookie = (name) => {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const history = createBrowserHistory();
const token = readCookie('ZakupkiChat.Token');


ReactDOM.render(
    <Router basename={baseUrl} history={history}>
        <App token={token} history={history} />
    </Router>,
    rootElement
);

registerServiceWorker();

