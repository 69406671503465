import React, { Component } from 'react';
import QRCode from 'qrcode.react';
import './style.scss'


export class GoToMobileScreen extends Component {
    static displayName = GoToMobileScreen.name;

    constructor(props) {
        super(props);

        this.link = this.props.link;
    }

    render() {
        return (
            <div className="go-to-mobile">
                <div className="go-to-mobile__box">
                    <div className="go-to-mobile__header">Чтобы открыть чат на мобильном устройстве, отсканируйте этот QR-код.</div>
                    <div className="go-to-mobile__body">
                        <QRCode
                            value={this.props.link}
                            className='go-to-mobile__qr-code'
                            renderAs='svg'
                            size={300}
                            level='L'
                            bgColor='#ffffff'
                            fgColor='#000000'
                            includeMargin={true}
                        />
                    </div>
                    <div className="go-to-mobile__footer">
                        <button
                            className="go-to-mobile__close-button btn btn-primary "
                            onClick={this.props.onClose}
                        >Закрыть</button>
                    </div>
                </div>
            </div>
        );
    }
}
