import React, { Component } from 'react';
import * as signalR from '@microsoft/signalr';
import { ChatHeader } from './ChatHeader';
import { ChatBody } from './ChatBody';
import { ChatFooter } from './ChatFooter';
import { ChatConditions } from './ChatConditions';
import { ChatMenu } from './ChatMenu';
import { GoToMobileScreen } from './GoToMobileScreen/GoToMobileScreen';
import update from 'react-addons-update';
import ym from 'react-yandex-metrika';
import './style.scss'


export class Chat extends Component {
    static displayName = Chat.name;

    constructor(props) {
        super(props);
        this.state = {
            messages: [],
            total: 0,
            isConnected: false,
            isJoined: true,
            isInitLoaded: false,
            user: null,
            tenderInfo: null,
            isConditionSubmited: localStorage.getItem('isConditionSubmited') != null,
            isMenuOpen: false,
            isTenderInfoOpen: false,
            isSendEnabled: true,
            isGoToMobileOpen: false
        };

        this.id = this.props.id;
        this.goToMobileLink = `https://zakupki.chat/${this.id}?access_token=${this.props.token}`;

        this.sendInterval = 2500;

        this.componentDidMount = this.componentDidMount.bind(this);
        this.sendMessage = this.sendMessage.bind(this);

        this.onConditionsSubmit = this.onConditionsSubmit.bind(this);

        this.loadTenderInfo = this.loadTenderInfo.bind(this);
        this.loadMessages = this.loadMessages.bind(this);

        this.onMenuClick = this.onMenuClick.bind(this);
        this.toggleTenderInfo = this.toggleTenderInfo.bind(this);
        this.toggleGoToMobile = this.toggleGoToMobile.bind(this);
    }

    componentDidMount() {
        document.title = "№" + this.id;

        const hubLocation =
            process.env.NODE_ENV === 'production' ?
                "https://hub.zakupki.chat" :
                "http://localhost:5050";

        const signalRLogLevel =
            process.env.NODE_ENV === 'production' ?
                signalR.LogLevel.Error :
                signalR.LogLevel.Debug;

        this.hubConnection = new signalR.HubConnectionBuilder()
            .configureLogging(signalRLogLevel)
            .withUrl(
                hubLocation + "?chatId=" + this.id,
                { accessTokenFactory: () => this.props.token }
            )
            .withAutomaticReconnect()
            .build();

        this.hubConnection.on('Login', (evt) => {
            console.log("Login as:");
            console.log(evt.user);
            this.setState({ ...this.state, user: evt.user });

            this.loadMessages();

            if (!this.state.tenderInfo) {
                this.loadTenderInfo();
            }  

            ym('reachGoal', 'chatLogin');
        });

        this.hubConnection.on('ReceiveMessage', (message) => {
            console.log(message);
            const messages = this.state.messages.concat([message]);
            this.setState({ ...this.state, messages, total: this.state.total + 1 });
        });

        this.hubConnection.on('DeleteMessages', (messageIdsToDelete) => {
            console.log("Delete messages:");
            console.log(messageIdsToDelete);

            const messages = this.state.messages.map((item) => {  
                if (messageIdsToDelete.includes(item.id)) {
                    return {...item, isDeleted: true };
                }
                return item;
            });

            this.setState(state => { return { ...this.state, messages } });
        });


        this.hubConnection.onreconnecting = (error) => {
            console.log('Disconnected! ' + error);
            console.log('Reconnecting!');

            this.setState({
                ...this.state,
                messages: [],
                total: 0,
                isConnected: false,
                isJoined: true,
                isInitLoaded: false
            });
        }

        this.hubConnection.onreconnected = (connectionId) => {
            console.log('Reconnected!');
            this.setState({
                ...this.state,
                messages: [],
                total: 0,
                isInitLoaded: false,
                isConnected: true
            });
        }

        this.hubConnection.onclose = (error) => {
            console.error("Disconnected!. " + error);

            this.setState({
                ...this.state,
                isConnected: false
            });
        }

        this.hubConnection
            .start()
            .then(() => {
                console.log('Connection started!');
                this.setState({
                    ...this.state,
                    isConnected: true
                });
            })
            .catch(err => console.error('Error while establishing connection :('));
    }

    componentWillUnmount() {
        if (this.timer) {
            clearInterval(this.timer);
        }

        if (this.hubConnection) {
            this.hubConnection.off('Login');
            this.hubConnection.off('ReceiveMessage');
            this.hubConnection.stop();
        } 
    }

    sendMessage(text) {
        if (!this.state.isJoined) {
            return;
        }

        let sendText = text.trim();
        if (sendText) {
            this.hubConnection
                .invoke('SendMessage', this.id, text)
                .catch(err => console.error(err));

            this.setState({
                ...this.state,
                isSendEnabled: false
            });

            setInterval(
                () => {
                    this.setState({
                        ...this.state,
                        isSendEnabled: true
                    })
                },
                this.sendInterval
            );

            ym('reachGoal', 'messageSent');
        }
    }

    loadTenderInfo() {
        this.hubConnection
            .invoke("GetTenderInfo", this.id)
            .then((result) => {
                console.log("Tender info loaded:");
                console.log(result);

                this.setState({
                    ...this.state,
                    tenderInfo: result
                });
            })
            .catch(err => console.error(err));
    }

    loadMessages() {
        let maxCount = 100;
        let count = maxCount;
        let offset = this.state.messages.length;
        let isFromTale = true;

        if (this.state.isInitLoaded) {
            isFromTale = false;
            let delta = this.state.total - this.state.messages.length;
            if (delta <= 0) {
                return;
            }
            count = delta > maxCount ? maxCount : delta;
            offset = delta - count;
        }
        
        this.hubConnection
            .invoke("GetMessages", this.id, count, offset, isFromTale)
            .then((result) => {
                console.log("Messages loaded:");
                console.log(result);

                const newMessages = result.messages.concat(this.state.messages);

                this.setState({
                    ...this.state,
                    messages: newMessages,
                    total: result.total,
                    isInitLoaded: true
                });
            })
            .catch(err => console.error(err));
    }

    onConditionsSubmit() {
        this.setState({
            ...this.state,
            isConditionSubmited: true
        });

        try {
            localStorage.setItem("isConditionSubmited", true);
        } catch (error) { } 
    }

    onMenuClick() {
        this.setState({
            ...this.state,
            isMenuOpen: !this.state.isMenuOpen,
            isTenderInfoOpen: false
        });
    }

    toggleTenderInfo() {
        this.setState({
            ...this.state,
            isTenderInfoOpen: !this.state.isTenderInfoOpen,
            isMenuOpen: false
        });  
    }

    toggleGoToMobile() {
        this.setState({
            ...this.state,
            isGoToMobileOpen: !this.state.isGoToMobileOpen,
        });
    }

    render() {
        let isFluid = false;

        return (
            <div id="chat-page">
                <ChatHeader
                    id={this.props.id}
                    fluid={isFluid}
                    user={this.state.user}
                    info={this.state.tenderInfo}
                    onMenuClick={this.onMenuClick}
                    onTenderInfoToggle={this.toggleTenderInfo}
                    isTenderInfoOpen={this.state.isTenderInfoOpen}
                    isMenuOpen={this.state.isMenuOpen}
                />

                <ChatBody
                    id={this.props.id}
                    fluid={isFluid}
                    messages={this.state.messages}
                    user={this.state.user}
                    isInitLoaded={this.state.isInitLoaded}
                    isConditionSubmited={this.state.isConditionSubmited}
                    onScrollTop={this.loadMessages}
                />

                {
                    this.state.isJoined && this.state.isConditionSubmited && this.state.isConnected &&
                    <ChatFooter
                        id={this.props.id}
                        fluid={isFluid}
                        onSendMessage={this.sendMessage}
                        user={this.state.user}
                        isSendEnabled={this.state.isSendEnabled}
                    />
                }

                {
                    !this.state.isConditionSubmited &&
                    <ChatConditions
                        onSubmit={this.onConditionsSubmit}
                    />
                }

                <ChatMenu
                    user={this.state.user}
                    isOpen={this.state.isMenuOpen}
                    chatId={this.id}
                    token={this.props.token}
                    goToMobileToggle={this.toggleGoToMobile}
                />

                {
                    this.state.isGoToMobileOpen &&
                    <GoToMobileScreen
                        link={this.goToMobileLink}
                        onClose={this.toggleGoToMobile}
                    />
                }
            </div>
        );
    }
}
