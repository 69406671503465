import React, { Component } from 'react';
import { UserPhoto } from '../UserPhoto';

export class ChatMessage extends Component {
    static displayName = ChatMessage.name;

    constructor(props) {
        super(props);

        this.message = this.props.message;
        this.user = this.props.message.user;
        this.formatedContent = this.message.content.split('\n').map((item, index) => {
            return (index === 0) ? item : [<br key={index} />, item]
        });

        if (this.message.isDeleted) {
            this.formatedContent = "Удалено модератором.";
        }

        if (this.message.date) {
            let date = new Date(this.message.date);
            this.formatedTime = date.toLocaleTimeString("ru", {
                'hour': '2-digit',
                'minute': '2-digit'
            });
        }
    }

    render() {
        return (
            <div data-message-id={this.message.id} className={this.props.isMy ? "chat-message chat-message_my" : "chat-message"}>
                <UserPhoto user={this.user} className="user-photo chat-message__user-photo" />
                <div className="chat-message__box">
                    <div data-user-id={this.user.id} className="chat-message__header">
                        <span className="chat-message__user-name">{this.user.name}</span>
                        {
                            this.formatedTime &&
                            <time className="chat-message__time">{this.formatedTime}</time>
                        }
                    </div>
                    <div className="chat-message__body">{this.formatedContent}</div> 
                </div>
            </div>
        );
    }
}
