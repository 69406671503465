import React, { Component } from 'react';

export class UserPhoto extends Component {
    static displayName = UserPhoto.name;

    constructor(props) {
        super(props);

        this.viewId = this.props.user && this.props.user.viewId ? this.props.user.viewId : "default";
        this.name = this.props.user && this.props.user.name ? this.props.user.name : "����������";
    }

    render() {
        return (
            <img
                src={`${process.env.PUBLIC_URL}/images/avatars/${this.viewId}.svg`}
                alt={this.name}

                {...this.props}
            />
        );
    }
}
