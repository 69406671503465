import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/home/Home';
import { Chat } from './components/chat/Chat';
import { InfoPage } from './components/info/InfoPage';
import ym, { YMInitializer } from 'react-yandex-metrika';
import './common.scss'

export default class App extends Component {
    static displayName = App.name;

    componentDidMount() {
        ym('hit', this.props.history.location.pathname);

        this.props.history.listen((location) => {
            ym('hit', location.pathname);
        });
    }

    render() {
        return (
            <Layout>
                <YMInitializer
                    accounts={[62625718]}
                    version="2"
                    options={{
                        webvisor: true,
                        defer: true,
                        clickmap: true,
                        trackLinks: true,
                        accurateTrackBounce: true
                    }}
                />

                <Route exact path='/' render={(props) => {
                    return <Home token={this.props.token} history={this.props.history} />;
                }} />

                <Route exact path='/:id' render={(props) => {
                    let id = decodeURIComponent(props.match.params.id);
                    let validId = id.replace(/[^\d]+/g, '');

                    return <Chat token={this.props.token} id={validId} />;
                }} />

                <Route exact path='/info/:pageName' render={(props) => {
                    const href = "/html/" + props.match.params.pageName + ".html";

                    return <InfoPage href={href} />;
                }} />
            </Layout>
        );
    }
}
