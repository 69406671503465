import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

export class ChatMenu extends Component {
    static displayName = ChatMenu.name;

    render() {
        const menuClass = this.props.isOpen ? 'shown' : '';

        return (
            <div id="chat-menu" className={menuClass}>
                <div className="menu-section">
                    <span>Этот чат полностью анонимен. <br /></span>
                    {
                        this.props.user &&
                        <span>
                            <span>Вы вошли как: <br /></span>
                            <span className="user-name">{this.props.user.name}</span>
                        </span>
                    }
                </div>
                <div className="menu-section">
                    <div className="title">Информация:</div>
                    <nav>
                        <ul>
                            <li><Link to="/info/about/">О сервисе</Link></li>
                            <li><Link to="/info/extension/">Расширение для браузера</Link></li>
                            <li><Link to="/info/license/">Лицензионное соглашение</Link></li>
                            <li><Link to="/info/privacy/">Политика конфиденциальности</Link></li>
                        </ul>
                    </nav>
                </div>
                <div className="menu-section">
                    <button className="btn btn-link" onClick={this.props.goToMobileToggle}>Открыть на мобильном</button>
                </div>
                <div className="menu-section">
                    <Link className="btn btn-primary w-100" to="/">Закрыть чат</Link>
                </div>
            </div>
        );
    }
}

export default withRouter(ChatMenu);
