import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { ChatMessage } from './ChatMessage';

export class ChatBody extends Component {
    static displayName = ChatBody.name;

    constructor(props) {
        super(props);

        this.lockedBottom = true;

        this.firstMessageElement = null;
        this.isLockedToPrevPosition = false;
        this.topMessageId = "";
        this.lastPrevPosition = 0;

        this.onScroll = this.onScroll.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.onScroll);
        window.addEventListener('wheel', this.onScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll);
        window.removeEventListener('wheel', this.onScroll);
    }

    componentDidUpdate() {
        const scrollElement = document.documentElement;
        const messageElements = document.getElementsByClassName("chat-message");

        if (this.lockedBottom) {
            scrollElement.scrollTop = scrollElement.scrollHeight - scrollElement.clientHeight;
        }
        else if (this.isLockedToPrevPosition) {
            scrollElement.scrollTop = this.firstMessageElement ? this.firstMessageElement.offsetTop - 75 : scrollElement.scrollTop;
            this.isLockedToPrevPosition = false;
        }

        this.firstMessageElement = messageElements.length ? messageElements[0] : null;
    }

    onScroll(evt) {
        const scrollElement = document.documentElement;
        this.lockedBottom = scrollElement.scrollHeight - scrollElement.clientHeight - scrollElement.scrollTop < 20;

        this.isLockedToPrevPosition = false;
        if (scrollElement.scrollTop === 0 && this.props.onScrollTop) {
            this.isLockedToPrevPosition = true;
            this.props.onScrollTop();
        }
    }

    render() {
        const showNoMesagesAlert = this.props.isInitLoaded && this.props.messages.length === 0;
        var currentDate = (new Date()).toLocaleDateString("ru");

        return (
            <main data-id={this.props.id} className="chat-body">
                {
                    showNoMesagesAlert &&
                    <Container fluid={this.props.fluid}>
                        <ChatMessage
                            key='init-message'
                            id='init-message'
                            message={{
                                id: 'init-message',
                                content: 'В этом чате еще никто ничего не писал. Напишите первое сообщение :)',
                                date: null,
                                user: {
                                    id: 'robot',
                                    name: 'Робот-Бобот',
                                    viewId: 'robot',
                                    role: 'base'
                                }

                            }}
                            isMy={false}
                        />
                    </Container>
                }

                {
                    this.props.messages.map((message, i) => {
                        let isMy = this.props.user && message.user && this.props.user.id === message.user.id;
                        let date = new Date(message.date);
                        let formatedDate = date.toLocaleDateString("ru");
                        let isNewDate = formatedDate !== currentDate;
                        currentDate = formatedDate;

                        return (
                            <div key={message.id}>
                                {
                                    isNewDate &&
                                    <div className="chat-body__date-delimiter">
                                        <div className="chat-body__date-delimiter-box">
                                            <div className="chat-body__date-delimiter-value">{formatedDate}</div>
                                        </div>
                                    </div>
                                }  
                                <Container fluid={this.props.fluid}>
                                    <ChatMessage id={message.id} message={message} isMy={isMy} />
                                </Container>
                            </div>
                        )
                    })
                }
            </main>
        );
    }
}
