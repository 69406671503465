import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import './style.scss'


export class InfoPage extends Component {
    static displayName = InfoPage.name;

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            isLoaded: false,
            content: ""
        };
    }

    componentDidMount() {
        document.title = "Чат закпок";

        fetch(this.props.href)
            .then(res => res.text())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        content: result
                    });
                    
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    render() {
        const backLink = this.props.backLink ? this.props.backLink : "/";

        return (
            <Container id="info-page">
                <div className="mb-5" dangerouslySetInnerHTML={{ __html: this.state.content }}/>
                <div className="text-center">
                    <Link to={backLink} className="btn btn-primary" >Закрыть</Link>
                </div>
            </Container>
        );
    }
}
