import React, { Component } from 'react';
import QRCode from 'qrcode.react';
import './style.scss';

export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);

        this.state = { chatId: "" };

        this.onSubmit = this.onSubmit.bind(this);
        this.onChatIdChange = this.onChatIdChange.bind(this);
    }

    componentDidMount() {
        document.title = "Чат закупок";
    }

    onChatIdChange(evt) {
        this.setState({
            ...this.state,
            chatId: evt.target.value.replace(/[^\d]+/g, '')
        });
    }

    onSubmit(evt) {
        const path = this.state.chatId.trim().replace(new RegExp('\\s', 'g'), '');
        if (path) {
            this.props.history.push('/' + encodeURIComponent(path));
        }
        evt.preventDefault();
        return false;
    }

    render() {
        return (
            <div id="home-page" className="flex-container">
                <div className='mobile d-none d-xl-block d-lg-block'>
                    <img className="mobile-bg" alt="Мобильная версия чата закупок" src={`${process.env.PUBLIC_URL}/images/mobile.svg`} />
                    <QRCode
                        value={'https://zakupki.chat'}
                        className='qr-code'
                        renderAs='svg'
                        size={110}
                        level='L'
                        bgColor='#ffffff'
                        fgColor='#4162ab'
                        includeMargin={true}
                    />
                </div>

                <div className="main-area">
                    <img className="logo" alt="Чат закупок" src={`${process.env.PUBLIC_URL}/images/logo.svg`} />
                    <h1>Чат закупок</h1>
                    <form onSubmit={this.onSubmit}>
                        <div className="input-group">
                            <input
                                ref="room_id"
                                id="room-id"
                                className="form-control"
                                type="text"
                                placeholder="Номер закупки..."
                                value={this.state.chatId}
                                onChange={this.onChatIdChange}
                            />
                            <button
                                type="submit"
                                className="btn btn-primary"
                                title="Перейти  в чат"
                            >
                                <i className="fa fa-chevron-right" />
                            </button>
                        </div>   
                    </form>   
                </div> 
            </div>
        );
    }
}
