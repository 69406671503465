import React, { Component } from 'react';
import { Container } from 'reactstrap';


export class ChatFooter extends Component {
    static displayName = ChatFooter.name;

    constructor(props) {
        super(props);
        this.state = { message: "" };

        this.onSubmit = this.onSubmit.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
    }

    onSubmit(evt) {
        if (this.state.message) {
            this.props.onSendMessage(this.state.message);
            this.setState({ ...this.state, message: "" });
        } 

        if (evt) {
            evt.preventDefault();   
        }
        return false;
    }

    onKeyPress(evt) {
        if (evt.which === 13 && !evt.shiftKey) {
            this.onSubmit();
            evt.preventDefault();
        }
    }

    onTextChange() {
        let maxLength = 200;
        let text = this.refs.chat_input.value;
        text = text.substring(0, maxLength);

        this.setState({ ...this.state, message: text });
    }

    render() {
        const sendEnabled = this.state.message.length > 0 && this.props.isSendEnabled;
        const buttonClass = sendEnabled ? "" : "disabled";

        return (
            <footer>
                <Container fluid={this.props.fluid}>
                    <form ref="chat_form" onSubmit={this.onSubmit}>
                        <div className="input-group">
                            <input
                                id="chat-input"
                                ref="chat_input"
                                className="form-control"
                                onChange={this.onTextChange}
                                onKeyPress={this.onKeyPress}
                                value={this.state.message}
                                placeholder="Напишите сообщение..."
                                autoComplete="off"
                            />

                            <button
                                id="send-button"
                                type="submit"
                                className={buttonClass}
                                title="Отправить"
                            >
                                <i className="fa fa-arrow-right" />
                            </button>
                        </div>
                    </form>
                </Container>
            </footer>
        );
    }
}
